import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';

// Elements
import BlogSidebar from '../elements/blog/blog-sidebar';

// Images
import TestimonialsPic1 from "../../images/testimonials/pic1.jpg"
import TestimonialsPic2 from "../../images/testimonials/pic2.jpg"
import TestimonialsPic3 from "../../images/testimonials/pic3.jpg"
import axios from "axios";
import base64 from 'react-native-base64';
import ReactHtml from 'raw-html-react';
import {Helmet} from "react-helmet";



const imgUrl = "https://lastikbulansadmin.stechomeyazilim.info";




class BlogDetails extends Component {
	constructor(){
		super()
		this.state={
			contactArray:[],
			blogLabel:[],
			BlogComment:[],
		}
	}

	componentWillUpdate(){
		this.getBlogDetails();
	}

	componentDidMount(){
		this.getBlogDetails();
		this.getBlogLabel();
		this.getBlogComment();


	}

   getBlogLabel= async () => {
        try {      

     axios.get(`https://konya.stechomeyazilim.info/apiwebyol/RestController.php?view=getBlogLabel&BlogLabel_BlogID=${this.props.location.state.ID}`)
      .then(res => {
                this.setState({
					blogLabel:res.data.cikti,
					
                      }, () => {
                      })
      })
        } catch (error) {
          console.log("error" + error)
        }
      }

	getBlogDetails= async () => {
        try {      

     axios.get(`https://konya.stechomeyazilim.info/apiwebyol/RestController.php?view=getBlogDetail&BlogDetail_ID=${this.props.location.state.ID}`)
      .then(res => {
            res.data.cikti.map((item) => {
                this.setState({
					ID:item.ID,
					Blog_Title :  item.Blog_Title,
					Blog_Long_Desc :  base64.decode(item.Blog_Long_Desc),
					Blog_Image_Url:item.Blog_Image_Url,
					Blog_Date: item.Blog_Date.substring(0,10),
					BlogRanking: item.BlogRanking,
					Blog_Writer: item.Blog_Writer,
                      }, () => {
                      })
              })
      })
        } catch (error) {
          console.log("error" + error)
        }
      }


	getBlogComment= async () => {
        try {      

     axios.get(`https://konya.stechomeyazilim.info/apiwebyol/RestController.php?view=getBlogComment&BlogComment_ID=${this.props.location.state.ID}`)
      .then(res => {
           this.setState({
					BlogComment:res.data.cikti
                      }, () => {
                      })
      })
        } catch (error) {
          console.log("error" + error)
        }
      }

      
	render(){
		return (
			<>
				<Header1 />
				
				{/* <!-- Content --> */}
				<div className="page-content bg-white">
					
					 <Helmet>
                <meta charSet="utf-8" />
             
            </Helmet>
					<div className="page-banner page-banner-sm">
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-black">{this.state.Blog_Title}</h1>
								<div className="breadcrumb-row text-black">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i>Ana Sayfa</Link></li>
										<li>{this.state.Blog_Title}</li>
									</ul>
								</div>
							 </div>
						</div>
					</div>
					
					<div className="content-block" id="content-area">
						
						<div className="section-area section-sp2 bg-white">
							<div className="container">
								<div className="row">
									<div className="col-md-12 col-lg-8 col-xl-8 m-md-b50 wow fadeInUp" data-wow-delay="0.2s">
										{/* <!-- blog start --> */}
										<div className="blog-lg blog-single">
											<div className="action-box blog-lg">
												<img src={imgUrl + this.state.Blog_Image_Url} alt=""/>
											</div>
											<div className="info-bx">
												<ul className="media-post">
													<li className="date"><Link to="#"><i className="fa fa-calendar-o"></i>{this.state.Blog_Date}</Link></li>
												</ul>
												<div className="ttr-post-title">
													<h4 className="post-title">{this.state.Blog_Title}</h4>
												</div>
												<div className="ttr-post-text">
													<p><ReactHtml html={this.state.Blog_Long_Desc}/></p>

												</div>
												<div className="ttr-divider bg-gray"><i className="icon-dot c-square"></i></div>
												<div className="ttr-post-meta d-flex">
													<div className="post-tags"> 

													{this.state.blogLabel.map((item) => (
							<Link to="#">{item.Label_Name}</Link> 
						))}

														
														
													</div>
													<div className="share-post ml-auto">
														<ul className="social">
															<li>Share:</li>
															<li><Link to="#"><i className="fa fa-facebook"></i></Link></li>
															<li><Link to="#"><i className="fa fa-twitter"></i></Link></li>
															<li><Link to="#"><i className="fa fa-pinterest"></i></Link></li>
														</ul>
													</div>
												</div>
											</div>
										</div>
										
										<div className="clear" id="comment-list">
											<div className="comments-area" id="comments">
												<h2 className="comments-title">{this.state.BlogComment.length} Yorumlar</h2>
												<div className="clearfix">
													{/* <!-- comment list END --> */}
													<ol className="comment-list">

													{this.state.BlogComment.map((item) => (
						
						<li className="comment">
															<div className="comment-body">
																<div className="comment-author vcard"> <img  className="avatar photo" src={TestimonialsPic1} alt=""/> <cite className="fn">{item.Comment_Full_Name}</cite> <span className="says">says:</span> </div>
																<div className="comment-meta"> <Link to="#">{item.Comment_Date.substring(0,16)}</Link> </div>
																<p>{item.Comment_Message}</p>
																<div className="reply">  </div>
															</div>
															
													
														</li>

						))}

													</ol>
													{/* <!-- comment list END --> */}
													{/* <!-- Form --> */}
													<div className="comment-respond" id="respond">
														<h4 className="comment-reply-title" id="reply-title">Cevap Yaz <small> <Link style={{display: "none"}} to="#" id="cancel-comment-reply-link" rel="nofollow">Cancel reply</Link> </small> </h4>
														<form className="comment-form" id="commentform" method="post">
															<p className="comment-form-author">
																<label htmlFor="author">Adınız <span className="required">*</span></label>
																<input type="text" defaultValue="" name="Author"  placeholder="Adınız" id="author"/>
															</p>
															<p className="comment-form-email">
																<label htmlFor="email">Mail Adresiniz <span className="required">*</span></label>
																<input type="text" defaultValue="" placeholder="Mail Adresiniz" name="email" id="email"/>
															</p>
														
															<p className="comment-form-comment">
																<label htmlFor="comment">Yorumunuz</label>
																<textarea rows="8" name="comment" placeholder="Yorumunuz" id="comment"></textarea>
															</p>
															<p className="form-submit">
																<input type="submit" defaultValue="Yorumu Gönder" className="submit" id="submit" name="submit"/>
															</p>
														</form>
													</div>
													{/* <!-- Form --> */}
												</div>
											</div>
										</div>
										{/* <!-- blog END --> */}
									</div>
									<div className="col-md-12 col-lg-4 col-xl-4 sticky-top wow fadeInUp" data-wow-delay="0.4s">
										<BlogSidebar />
									</div>
								</div>
							</div>
						</div>
						
					</div>
					
				</div>
				
				<Footer1 />
				
			</>
		);
	}
}

export default BlogDetails;