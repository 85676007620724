import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';

// Elements
import BlogSidebar from '../elements/blog/blog-sidebar';

// Images
import GridPic2 from "../../images/blog/grid/pic2.jpg"
import base64 from 'react-native-base64';
import ReactHtml from 'raw-html-react';
import axios from "axios";

const blogsUrl = "https://konya.stechomeyazilim.info/apiwebyol/RestController.php?view=getBlog";
const imgUrl = "https://lastikbulansadmin.stechomeyazilim.info";

class BlogClassicSidebar extends Component {
	constructor(){
		super()
		this.state={
			blogsArray:[]
		}
	}
	componentDidMount(){
		this.getBlogs();
	}

	getBlogs = async () => {
		try {
		  axios.get(blogsUrl).then((res) => {
			this.setState(
			  {
				blogsArray: res.data.cikti,
			  },
			  () => {}
			);
		  });
		} catch (error) {
		  console.log("error" + error);
		}
	  };
	
	render(){
		return (
			<>
				<Header1 />
				
				{/* <!-- Content --> */}
				<div className="page-content bg-white">
					
					<div className="page-banner page-banner-sm">
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-black">Blog</h1>
								<div className="breadcrumb-row text-black">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i> Ana Sayfa</Link></li>
										<li>Blog</li>
									</ul>
								</div>
							 </div>
						</div>
					</div>
					
					<div className="content-block" id="content-area">
						
						<div className="section-area section-sp2 bg-white">
							<div className="container">
								<div className="row">
									<div className="col-md-12 col-lg-8 col-xl-8 m-md-b50">
										<div className="row">
											{this.state.blogsArray.map(item=>(
												<div className="col-lg-6 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0.4s">
												<div className="recent-news">
													<div className="action-box">
														<img src={imgUrl + item.Blog_Image_Url} alt=""/>
													</div>
													<div className="info-bx">
														<ul className="media-post">
															<li className="date"><Link to={{pathname: `/blog-detay/${item.Blog_Title.replace(/\s+/g, '-').toLowerCase()}`, state: { ID: item.ID }}}><i className="fa fa-calendar-o"></i>{item.Blog_Date.slice(0,10)}</Link></li>
														</ul>
														<h4 className="post-title"><Link to={{pathname: `/blog-detay/${item.Blog_Title.replace(/\s+/g, '-').toLowerCase()}`, state: { ID: item.ID }}}>{item.Blog_Title}</Link>

														</h4>
														<p><ReactHtml html={base64.decode(item.Blog_Short_Desc)}/></p>
														<div className="post-extra">
															<Link to={{pathname: `/blog-detay/${item.Blog_Title.replace(/\s+/g, '-').toLowerCase()}`, state: { ID: item.ID }}}>BLOG DETAYI</Link>
														</div>
													</div>
												</div>
											</div>
											))}
											
											
											<div className="col-lg-12">
												<div className="pagination-bx gray clearfix">
													<ul className="pagination">
														<li className="previous"><Link to="#"><i className="ti-arrow-left"></i> Geri</Link></li>
														<li className="active"><Link to="#">1</Link></li>
														<li><Link to="#">2</Link></li>
														<li><Link to="#">3</Link></li>
														<li className="next"><Link to="#">İleri <i className="ti-arrow-right"></i></Link></li>
													</ul>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-12 col-lg-4 col-xl-4 sticky-top wow fadeInUp" data-wow-delay="0.4s">
										<BlogSidebar />
									</div>
								</div>
							</div>
						</div>		
						
					</div>
					
				</div>
				
				<Footer1 />
				
			</>
		);
	}
}

export default BlogClassicSidebar;