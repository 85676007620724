import React, { Component } from "react";
import { Link } from "react-router-dom";
import Masonry from "react-masonry-component";
import SimpleReactLightbox, {
  SRLWrapper,
  useLightbox,
} from "simple-react-lightbox";


import axios from "axios";


const urlCategory =
  "https://konya.stechomeyazilim.info/apiwebyol/RestController.php?view=getProjectCategory";

  const imgUrl = "https://lastikbulansadmin.stechomeyazilim.info";


// Magnific Anchor
const MagnificAnchor = (props) => {
  const { openLightbox } = useLightbox();

  return (
    <Link
      to={"#"}
      onClick={() => openLightbox(props.imageToOpen)}
      className="magnific-anchor"
    >
      <i className="la la-plus"></i>
    </Link>
  );
};

const options = {
	settings: {
		overlayColor: "rgba(0,0,0,0.9)",
		backgroundColor: "#fe5a0e",
		slideAnimationType: 'slide',
	},
	buttons: {
		backgroundColor: "#fe5a0e",
		iconColor: "rgba(255, 255, 255, 1)",
		showAutoplayButton: false,
		showDownloadButton: false,
	},
	caption: {
		captionColor: "#a6cfa5",
		captionFontFamily: "Raleway, sans-serif",
		captionFontWeight: "300",
		captionTextTransform: "uppercase",
	}
};

class PortMasonry extends Component {
  constructor() {
    super();
    this.state = {
      tag: "",
      setTag: "",
      filteredImages: [],
      categories: [],
      setFilterdImages: [],
    };
  }
  //handleChange = e => this.setState({ [e.target.name]: e.target.value });

  componentDidMount() {
    this._getBlog("");
    this._getCategory();
  }
  _getBlog = async (value) => {
    try {
      axios
        .get(
          `https://konya.stechomeyazilim.info/apiwebyol/RestController.php?view=getProject&ProjectCategory_ID=${value}`
        )
        .then((res) => {
          this.setState(
            {
              filteredImages: res.data.cikti,
            },
            () => {}
          );
        });
    } catch (error) {
      console.log("error" + error);
    }
  };
  _getCategory = async () => {
    try {
      axios.get(urlCategory).then((res) => {
        this.setState(
          {
            categories: res.data.cikti,
          },
          () => {}
        );
      });
    } catch (error) {
      console.log("error" + error);
    }
  };
  render() {
    return (
      <>
        <div className="feature-filters clearfix center m-b40 style1">
          <ul className="filters" data-toggle="buttons">
            <li
              className={`${this.state.activeFilter ? "btn active" : "btn"}`}
              onClick={() => this._getBlog("")}
            >
              <Link onClick={() => this._getBlog("")}>
                <span>{"Bütün Hizmetlerimiz"}</span>
              </Link>
            </li>
            {this.state.categories.map((category) => (
              <li
                className={`${this.state.activeFilter ? "btn active" : "btn"}`}
                onClick={() => this._getBlog(category.ID)}
              >
                <Link onClick={() => this._getBlog(category.ID)}>
                  <span>{category.ProjectCategory_Name}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <SimpleReactLightbox>
          <SRLWrapper options={options}>
            <Masonry className="row sp4">
              {this.state.filteredImages.map((item, index) => (
                <div
                  className="action-card col-lg-6 col-md-6 col-sm-12"
                  key={index}
                >
                  <div className="portfolio-box style-1">
                    <div className="portfolio-media">
                      <img src={item.Project_Main_Image} alt="" />
                    </div>
                    <div className="portfolio-info">
                      <h4 className="title">
                        <Link to={"projects-single-1"}>
                          {item.Project_Title}
                        </Link>
                      </h4>
                      <span className="exe-title">{item.ProjectCategory_Name}</span>
                      <MagnificAnchor />
                    </div>
                  </div>
                </div>
              ))}
            </Masonry>
          </SRLWrapper>
        </SimpleReactLightbox>
      </>
    );
  }
}
export default PortMasonry;
