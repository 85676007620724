import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import IconImg1 from "../../../images/icon/contact/icon1.png"
import IconImg2 from "../../../images/icon/contact/icon2.png"
import IconImg3 from "../../../images/icon/contact/icon3.png"
import axios from "axios";

const servicesUrl = "https://konya.stechomeyazilim.info/apiwebyol/RestController.php?view=getService";
const contactUrl = "https://konya.stechomeyazilim.info/apiwebyol/RestController.php?view=getContact";

class Footer1 extends Component{
	constructor(){
		super()
		this.state={
			contactArray:[],
			servicesArray:[],
		}
	}
	getSidebar = async () => {
		try {
		  axios.get(servicesUrl).then((res) => {
			this.setState(
			  {
				servicesArray: res.data.cikti,
			  },
			  () => {}
			);
		  });
		} catch (error) {
		  console.log("error" + error);
		}
	  };
	  getContact= async () => {
        try {      

     axios.get(contactUrl)
      .then(res => {
            res.data.cikti.map((item) => {
                this.setState({
					ID:item.ID,
					Adress_Text :  item.Adress_Text,
					Email_Adress:item.Email_Adress,
					Phone_Numer:item.Phone_Numer,
					Slogan_Home:item.Slogan_Home,
					Header_Text:item.Header_Text,
					Header_Icon: item.Header_Icon,
					Main_Logo: item.Main_Logo,
					Twitter_Adress: item.Twitter_Adress,
					Instagram_Adress: item.Instagram_Adress,
					Facebook_Adress: item.Facebook_Adress,
                      }, () => {
                      })
              })
      })
        } catch (error) {
          console.log("error" + error)
        }
      }
	componentDidMount() {

		this.getSidebar();
		this.getContact();
	}
	render(){
		return(
			<>
				{/* <!-- Footer ==== --> */}
				<footer className="footer-style2">
					<div className="footer-top bt0">
						<div className="container">
							<div className="row align-items-center footer-info">
								<div className="col-lg-3 col-md-6 col-sm-6 m-md-b30">
									<div className="feature-container left footer-info-bx">
										
										<div className="icon-content">

										<a target="_blank" href={"https://play.google.com/store/apps/details?id=com.lastikbulansyol"} style = {{color:'gray'}}><i className="fa fa-android  fa-3x "></i></a>
										<a target="_blank" href={"https://apps.apple.com/tr/app/lastikbulans/id1551542691?l=tr"} style = {{color:'gray', paddingLeft:15}}><i className="fa fa-apple fa-3x"></i></a>
											<h5 className="ttr-tilte">LastikBulans Mobil Uygulama</h5>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 col-sm-6 m-md-b30">
									<div className="feature-container left footer-info-bx">
										<div className="feature-lg text-white">
											<span className="icon-cell"><img src={IconImg1} alt=""/></span> 
										</div>
										<div className="icon-content">
											<h5 className="ttr-tilte">İletişim Numaralarımız</h5>
											<a href={"tel:"+this.state.Phone_Numer}>{this.state.Phone_Numer}</a>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 col-sm-6 m-sm-b30">
									<div className="feature-container left footer-info-bx">
										<div className="feature-lg text-white">
											<span className="icon-cell"><img src={IconImg2} alt=""/></span> 
										</div>
										<div className="icon-content">
											<h5 className="ttr-tilte">Email Adresimiz</h5>
											<p>{this.state.Email_Adress}</p>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 col-sm-6 text-left text-lg-right">
									<Link to="get-in-touch" className="btn btn-long d-sm-inline-block">Bize Ulaşın</Link>
								</div>
							</div>
						</div>
						<div className="container">
							<div className="row">
								<div className="col-12 col-lg-3 col-md-5 col-sm-6">
									<div className="widget footer_widget">
										<h5 className="footer-title">Servislerimiz</h5>
										<ul>
														{this.state.servicesArray.map(item =>(
														<li><Link to={{pathname: `/servislerimiz/${item.Service_Title.replace(/\s+/g, '-')}`, state: { ID: item.ID }}}>{item.Service_Title} </Link></li>
														))}
										</ul>
									</div>
								</div>
								<div className="col-12 col-lg-4 col-md-7 col-sm-6">
									<div className="widget list-2">
										<h5 className="footer-title">Firmamız</h5>
										<ul>
											<li><Link to="index">Ana Sayfa</Link></li>
											<li><Link to="hakkimizda">Hakkımızda</Link></li>
											<li><Link to="hizmetlerimiz">Hizmetlerimiz</Link></li>
											<li><Link to="sss">SSS</Link></li>
											<li><Link to="iletisim">Bize Ulaşın</Link></li>
										</ul>
									</div>
								</div>
								<div className="col-12 col-lg-5 col-md-12 col-sm-12">
									<div className="widget widget_info">
										<h5 className="footer-title">Bizi Ziyaret Edin</h5>
										{/* <p>Weekly Breaking News Analysis And Cutting Edge <br/>Advices On Job Searching.</p> */}
										<form className="subscribe-form m-b20" action="assets/script/mailchamp.php" method="post">
											<div className="ajax-message"></div>
											<div className="input-group">
											<div className="col-lg-12 col-md-12 p-lr0 d-flex">
													<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12588.56378362904!2d32.5570448!3d37.9271376!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x39bb083975db9aa2!2sMentis%20Engineering%20Mechanical%26Chemical%20Ltd.!5e0!3m2!1str!2str!4v1617200580743!5m2!1str!2str" className="align-self-stretch d-flex" style={{ width: "100%", minHeight: "100%"}} allowFullScreen></iframe>
											</div>
											</div>
										</form>
										<ul className="list-inline ft-social-bx">
											<li><a to={this.state.Facebook_Adress} className="btn button-sm"><i className="fa fa-facebook"></i></a></li>
											<li><a to={this.state.Twitter_Adress} className="btn button-sm"><i className="fa fa-twitter"></i></a></li>
											<li><a to={this.state.Instagram_Adress} className="btn button-sm"><i className="fa fa-instagram"></i></a></li>
											<li><a to={this.state.Facebook_Adress} className="btn button-sm"><i className="fa fa-google-plus"></i></a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="footer-bottom">
						<div className="container">
							<div className="row">
							<div className="col-lg-6 col-md-6 col-sm-12 text-center text-md-left">
									<p className="m-b0 "> Copyright © 2021 <Link style={{color:"#E2E5E7", opacity:"70%"}} className="link-inline" to={"www.stechome.com.tr/"}>Stechome </Link>
									Bütün Hakları Saklıdır.</p>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-12 text-center text-md-right">
									<ul className="link-inline">
										<li><Link to="/">Ana Sayfa</Link></li>
										<li><Link to="about">Hakkımızda</Link></li>
										<li><Link to="faq">SSS</Link></li>
										<li><Link to="contact-1">Contact</Link></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</footer>
			</>
		);
	}
}

export default Footer1;
