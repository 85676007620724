import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';

// Images
import Logo from "../../../images/logo.png"
import LogoWhite from "../../../images/logo-white.png"
import IconImg1 from "../../../images/icon/contact/icon1.png"
import IconImg2 from "../../../images/icon/contact/icon2.png"
import IconImg3 from "../../../images/icon/contact/icon3.png"
import axios from "axios";

const servicesUrl = "https://konya.stechomeyazilim.info/apiwebyol/RestController.php?view=getService";
const contactUrl = "https://konya.stechomeyazilim.info/apiwebyol/RestController.php?view=getContact";

class Header4 extends Component{
	constructor(){
		super()
		this.state={
			contactArray:[],
			servicesArray:[],
		}
	}




	getContact= async () => {
	console.log("dsadsad", this.props.deneme)
        try {      

     axios.get(contactUrl)
      .then(res => {
            res.data.cikti.map((item) => {
                this.setState({
					ID:item.ID,
					Adress_Text :  item.Adress_Text,
					Email_Adress:item.Email_Adress,
					Phone_Numer:item.Phone_Numer,
					Slogan_Home:item.Slogan_Home,
					Header_Text:item.Header_Text,
					Header_Icon: item.Header_Icon,
					Main_Logo: item.Main_Logo,
					Twitter_Adress: item.Twitter_Adress,
					Instagram_Adress: item.Instagram_Adress,
					Facebook_Adress: item.Facebook_Adress,
                      }, () => {
                      })
              })
      })
        } catch (error) {
          console.log("error" + error)
        }
      }
	  getSidebar = async () => {
		try {
		  axios.get(servicesUrl).then((res) => {
			this.setState(
			  {
				servicesArray: res.data.cikti,
			  },
			  () => {}
			);
		  });
		} catch (error) {
		  console.log("error" + error);
		}
	  };
	componentDidMount() {

		this.getContact();
		this.getSidebar();
		
	
		// Contact Sidebar
        var asideBtn = document.getElementById("aside-btnshow")
        var contactSidebar = document.querySelector(".contact-sidebar")
        var sidebtnShow = document.getElementById("contact-sidebtnshow")

        asideBtn.addEventListener('click',function(){
            contactSidebar.style.display = "block"
            contactSidebar.classList.add("open")
        })

        sidebtnShow.addEventListener('click',function(){
            contactSidebar.style.display = "none"
            contactSidebar.classList.remove("open")
        })

        // sidebar open/close

        var btn = document.querySelector('.menuicon');
        var nav = document.querySelector('.menu-links');
       
        function toggleFunc() {
            btn.classList.toggle("open");
            nav.classList.toggle("show");
        }

        btn.addEventListener('click', toggleFunc);


        // Sidenav li open close
        var navUl = [].slice.call(document.querySelectorAll('.header-nav > ul > li'));
        for (var y = 0; y < navUl.length; y++) {
            navUl[y].addEventListener('click', function () { checkLi(this) });
        }

        function checkLi(current) {
            const active = current.classList.contains("open")
            navUl.forEach(el => el.classList.remove('open'));
            //current.classList.add('open');
           
            if(active){
                current.classList.remove('open') 
                console.log("active")
            } else{
                current.classList.add('open');
                console.log("close")
            }
        }

    }
	
	render(){
		return(
			<>
				
				{/* <!-- Header Top ==== --> */}
				<header className="rs-nav style-3 box header">
					<div className="top-bar">
						<div className="container">
							<div className="row d-flex justify-content-between">
								<div className="topbar-left">
									<ul>
										<li><a href={"tel:"+this.state.Phone_Numer}><i className="la la-phone"></i>Bizi Arayın - {this.state.Phone_Numer}</a></li>
										<li><i className="la la-clock"></i>Haftanın Her Günü - 7/24 Yol Yardım</li>
										<li><a href={"mailto:"+this.state.Email_Adress}><i className="las la-envelope-open"></i>{this.state.Email_Adress}</a></li>
									</ul>
								</div>
								<div className="topbar-right">
									{/* <ul>
										<li><Link to="company-history">History</Link></li>
										<li><Link to="blog-standard">News</Link></li>
										<li><Link to="contact-1">Contact Us</Link></li>
									</ul> */}
								</div>
							</div>
						</div>
					</div>
					<div className="header-extra-info d-lg-block d-none">
						<div className="container">
							<div className="extra-info-inner">
								<div className="extra-logo">
									<Link to="/"><img src={Logo} alt=""/></Link>
								</div>
								<div className="header-contant-info">
									<ul>
										<li className="br">
											<i className="fa fa-clock-o icon"></i>
											<h5 className="title">Haftanın Her Günü</h5>
											<p>7/24 Yol Yardım</p>
										</li>
										<li>
											<i className="fa fa-phone icon"></i>
											<h5 className="title">Numaralarımız</h5>
											<a href={"tel:"+this.state.Phone_Numer}>{this.state.Phone_Numer}</a>

										</li>
										<li>
											<Link to="/iletisim" className="btn radius-sm">Bize Ulaşın</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<Sticky enabled={true} className="sticky-header navbar-expand-lg">
						<div className="menu-bar clearfix">
							<div className="container clearfix">
								{/* <!-- Header Logo ==== --> */}
								<div className="menu-logo d-lg-none d-table">
									<Link to="/"><img src={LogoWhite} alt=""/></Link>
								</div>
								{/* <!-- Mobile Nav Button ==== --> */}
								<button className="navbar-toggler collapsed menuicon justify-content-end" type="button" data-toggle="collapse" data-target="#menuDropdown" aria-controls="menuDropdown" aria-expanded="false" aria-label="Toggle navigation">
									<span></span>
									<span></span>
									<span></span>
								</button>
								{/* <!-- Author Nav ==== --> */}
								<div className="secondary-menu">
									<div className="secondary-inner">
										<ul>
											
											<li className="search-box">
												<form action="#">
												</form>
											</li>
											<li className="aside-box d-none d-lg-block">
												<Link id="aside-btnshow" className="aside-btn contact-sidebtn" to="#">
													<span></span>
													<span></span>
													<span></span>
												</Link>
											</li>
										</ul>
									</div>
								</div>
								{/* <!-- Navigation Menu ==== --> */}
								<div className="menu-links navbar-collapse collapse justify-content-start" id="menuDropdown">
									<div className="menu-logo">
										<Link to="/"><img src={Logo} alt=""/></Link>
									</div>
									<ul className="nav navbar-nav">	
										<li className="active"><Link to="/">Ana Sayfa<i className="fa "></i></Link>
											{/* <ul className="sub-menu">
												<li><Link to="/"><span>Home - Default</span> </Link></li>
												<li><Link to="index-2"><span>Home - Energy & Power</span></Link></li>
												<li><Link to="index-3"><span>Home - Construction</span></Link></li>
												<li><Link to="index-4"><span>Home - Agricultural</span></Link></li>
											</ul> */}
										</li>
										<li className="has-mega-menu "><Link to="hakkimizda">Hakkımızda<i className="fa fa"></i></Link>
											
										</li>
										<li><Link to="#">Servislerimiz<i className="fa fa-chevron-down"></i></Link>
											<ul className="sub-menu">
												<li className="add-menu-left">
													<ul>
														{this.state.servicesArray.map(item =>(
														<li><Link to={{pathname: `/servislerimiz/${item.Service_Title.replace(/\s+/g, '-')}`, state: { ID: item.ID }}}>{item.Service_Title} </Link></li>
														
														))}
														
													</ul>
												</li>
											</ul>
										</li>
										<li><Link to="sss">SSS<i className="fa"></i></Link>
											
										</li>
										<li className="has-mega-menu "><Link to="hizmetlerimiz">Hizmetlerimiz <i className="fa"></i></Link>
											
										</li>
										
										<li><Link to="blog">Blog <i className="fa"></i></Link>
											
										</li>

										
									</ul>
									<div className="nav-social-link">
										<Link to="#"><i className="fa fa-facebook"></i></Link>
										<Link to="#"><i className="fa fa-google-plus"></i></Link>
										<Link to="#"><i className="fa fa-linkedin"></i></Link>
									</div>
								</div>
								{/* <!-- Navigation Menu END ==== --> */}
							</div>
						</div>
					</Sticky>
				</header>
				{/* <!-- Header Top END ==== --> */}
				<div className="contact-sidebar content-scroll">
					<div className="inner">
						<div className="logo">
							<img src={Logo} alt=""/>
						</div>
						<h4 className="title">Yol Yardım Desteği İçin Bize Ulaşın</h4>
						<ul className="contact-infolist">
							<li>
								<img src={IconImg1} alt=""/>
								<h6 className="contact-title">Numaralarımız</h6>
								<a href={"tel:"+this.state.Phone_Numer}><p>{this.state.Phone_Numer}</p></a>
							</li>
							<li>
								<img src={IconImg2} alt=""/>
								<h6 className="contact-title">Email Adresimiz</h6>
								<a href={"mailto:"+this.state.Email_Adress}>{this.state.Email_Adress}</a><br/>
							</li>
							<li>
								<img src={IconImg3} alt=""/>
								<h6 className="contact-title">Adresimiz</h6>
								<p>{this.state.Adress_Text}</p>
							</li>
						</ul>
						<h4 className="title">Bize Ulaşın</h4>
						<form className="contact-bx ajax-form" action="script/contact.php">
							<div className="ajax-message"></div>
							<div className="row placeani">
								<div className="col-lg-12">
									<div className="form-group">
										<div className="input-group">
											<label>İsminiz</label>
											<input name="name" type="text" required className="form-control"/>
										</div>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="form-group">
										<div className="input-group"> 
											<label>E-mail Adresiniz</label>
											<input name="email" type="email" className="form-control" required />
										</div>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="form-group">
										<div className="input-group">
											<label>Mesajınız</label>
											<textarea name="message" rows="4" className="form-control" required ></textarea>
										</div>
									</div>
								</div>
								<div className="col-lg-12">
									<button name="submit" type="submit" value="Submit" className="btn button-md">Gönder</button>
								</div>
							</div>
						</form>
					</div>
				</div>
				<div id="contact-sidebtnshow" className="contact-sidebtn close">
					<i className="ti-close"></i>
				</div>
			</>
		);
	}
}

export default Header4;