import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";

// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';

// Elements
import ClientLogo from '../elements/client-logo/client-logo-1';

// Images
import Banner1 from '../../images/banner/banner1.jpg';
import Icon1 from '../../images/icon/contact/icon1.png';
import Icon3 from '../../images/icon/contact/icon3.png';

const urlCategory =
  "https://konya.stechomeyazilim.info/apiwebyol/RestController.php?view=getProjectCategory";


class GetInTouch extends Component {
	 constructor() {
    super();
    this.state = {
      categories: [],
    };
  }

  componentDidMount() {
    this._getCategory();
  }

	 _getCategory = async () => {
    try {
      axios.get(urlCategory).then((res) => {
        this.setState(
          {
            categories: res.data.cikti,
          },
          () => {}
        );
      });
    } catch (error) {
      console.log("error" + error);
    }
  };

	render(){
		return (
			<>
				<Header1 />
				
				{/* <!-- Content --> */}
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark parallax" style={{backgroundImage: "url("+Banner1+")"}}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">Bize Ulaşın</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
										<li>Bize Ulaşın</li>
									</ul>
								</div>
							 </div>
						</div>
					</div>
					
					<div className="content-block" id="content-area">
						
						<div className="section-area bg-gray section-sp2">
							<div className="container">
								<div className="row">
									<div className="col-lg-8 col-md-7">
										<div className="heading-bx">
											<h6 className="title-ext m-b0">Bize Ulaşın</h6>
											<h3 className="title-head m-b0">Destek Alabilmek için Aşağıdaki Formu Eksiksiz Doldurunuz</h3>
											<div className="ttr-separator sepimg"></div>

										</div>
										<div className="row m-b30">
											<div className="col-md-12">
												<form className="ajax-form form-area-box get-in-touch" action="script/contact.php">
													<div className="ajax-message"></div>
													<div className="row placeani">
														<div className="col-lg-6">
															<div className="form-group">
																<div className="input-group">
																	<label>Adınız*</label>
																	<input name="name" type="text" className="form-control" required/>
																</div>
															</div>
														</div>
														
														
														<div className="col-lg-6">
															<div className="form-group">
																<div className="input-group">
																	<label>Telefon Numaranız*</label>
																	<input name="phone" type="text" className="form-control" required />
																</div>
															</div>
														</div>
														<div className="col-lg-12">
															<div className="row m-b20">
																<div className="col-md-12 col-lg-12">
																	<h5>Hizmetlerimiz</h5>
																</div>

																 {this.state.categories.map((category) => (
            	<div className="col-md-12 col-lg-4">
																	<div className="custom-control custom-checkbox m-b10">
																		<input type="checkbox" className="custom-control-input" id="customControlAutosizing1" />
																		<label className="custom-control-label" htmlFor="customControlAutosizing1">{category.ProjectCategory_Name }</label>
																	</div>
																</div>

            ))}

																
															</div>
														</div>
														
														
														<div className="col-lg-12">
															<div className="form-group">
																<div className="input-group">
																	<label>Sorununuz*</label>
																	<textarea className="form-control" required></textarea>
																</div>
															</div>
														</div>
														<div className="col-lg-12">
															<button name="submit" type="submit" value="Submit" className="btn button-md"> Formu Gönder</button>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
									<div className="col-lg-4 col-md-5">
										<aside className="sticky-top left-border-1">
											<div className="widget">
												<h6 className="widget-title">Diğer Sayfalar</h6>
												<ul className="service-list style-2">
													


													
													<li><Link to="appointment">Appointment<i className="las la-address-card"></i></Link></li>
													<li className="active"><Link to="client">Clients<i className="las la-user-circle"></i></Link></li>
													<li><Link to="faq">SSS<i className="las la-quote-left"></i></Link></li>
												</ul>
												<Link to="get-in-touch" className="btn btn-block m-t20">Bize Ulaşın</Link>
											</div>
											<div className="widget">
												<h6 className="widget-title">İletişim</h6>
												<ul className="contact-infolist">
													<li>
														<img src={Icon1} alt="" className="mCS_img_loaded"/>
														<h6 className="contact-title">İletişim Numarası</h6>
														<p>+905510315421 <br/></p>
													</li>
													
													<li>
														<img src={Icon3} alt="" className="mCS_img_loaded"/>
														<h6 className="contact-title">Adresimiz</h6>
														<p>...</p>
													</li>
												</ul>
											</div>
										</aside>
									</div>
								</div>
								
							</div>
						</div>
						
						<div className="section-area section-sp2 bg-white">
							<div className="container wow fadeIn" data-wow-delay="0.8s">
								<div className="heading-bx text-center">
									<h6 className="title-ext m-b0">Referanslarımız</h6>
									<div className="ttr-separator sepimg"></div>
								</div>
								<ClientLogo />
							</div>
						</div>
						
					</div>
					
				</div>
				
				<Footer1 />
				
			</>
		);
	}
}

export default GetInTouch;