import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import axios from "axios";
import ReactLoading from "react-loading";


var options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

 let crdVariable = null;
 let crdVariableCheck = false;

async function success(pos) {

crdVariable = pos.coords;
crdVariableCheck = true;



  const APIKEY = 'AIzaSyBocJDGN0UtiQXa-R4Aa7salQeABS9XdBA';

      const url = `https://maps.googleapis.com/maps/api/geocode/json?key=${APIKEY}&latlng=${crdVariable.latitude},${crdVariable.longitude}&sensor=true`;


      let rsp=await fetch(url)
      rsp=await rsp.json()



 axios({
            method: "POST",
            url: "https://lastikbulans.herokuapp.com/pushNotification/",
            data: ({
        USERID: 3,
        title:"Lastikbulans Türkiye",
        body:rsp.results[0].formatted_address,
    }),
            headers: {
              "Content-Type": "application/json"
            }
          }).then(res => {
           
          });

}

function errors(err) {
  console.warn(`ERROR(${err.code}): ${err.message}`);
}


class ServiceSlider1 extends Component{
	constructor(){
		super()
		this.state={
					isLoading:false,

		}

	}

_setLocation = async () => {


  this.setState({isLoading:true});

  if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.success, errors, options);
}else {
    
}


}


	success = async (pos) => {

crdVariable = pos.coords;


  const APIKEY = 'AIzaSyBocJDGN0UtiQXa-R4Aa7salQeABS9XdBA';

      const url = `https://maps.googleapis.com/maps/api/geocode/json?key=${APIKEY}&latlng=${crdVariable.latitude},${crdVariable.longitude}&sensor=true`;


      let rsp=await fetch(url)
      rsp=await rsp.json()



 axios({
            method: "POST",
            url: "https://lastikbulans.herokuapp.com/pushNotification/",
            data: ({
        USERID: 3,
        title:"Lastikbulans Türkiye",
        body:rsp.results[0].formatted_address,
    }),
            headers: {
              "Content-Type": "application/json"
            }
          }).then(res => {
             this.setState({isLoading:false});

          });



    axios({
            method: "POST",
            url: "https://lastikbulans.herokuapp.com/work/insert",
            data: ({
        Location_Text: rsp.results[0].formatted_address,
        Location_City:  rsp.results[4].formatted_address,
        Location_Long:crdVariable.longitude,
        Location_Lat:crdVariable.latitude,
        Customer_ID:1166,
        Worker_ID:73,
        Worker_State:0,
        Work_Note:"website",
        Work_Status_ID:1,
        Service_2_ID:1,
        Car_Type_ID:4,
        Work_Send_Worker_ID:3,
        Work_WebSite_Check:true,
    }),
            headers: {
              "Content-Type": "application/json"
            }
          }).then(res => {

  this.setState({isLoading:false});

         alert("Bize Konumunuz Başarıyla Gönderildi, Sizinle İletişime Geçeceğiz");

          });

         alert("Bize Konumunuz Başarıyla Gönderildi, Sizinle İletişime Geçeceğiz");

                       this.setState({isLoading:false});


}
	render(){
		
		const settings = {
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 4,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 1199,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		};
				
		return(
			<>
			
				<Slider {...settings} className="slider-sp0 arrow-none slider-process">
					<div className="slider-item">
						<div className="process-box">
							<h5 className="title"><i className="ti-world"></i> LastikBulans Konya</h5>
							<p>Türkiye'nin 20 iline yol yardım himzeti vermekteyiz.</p>
							<Link to="/hizmetlerimiz" className="process-btn">Hizmetlerimiz <i className="ti-arrow-right"></i></Link>
						</div>
					</div>
					<div className="slider-item">
						<div className="process-box">
							<h5 className="title"><i className="ti-shield"></i> 7/24 Yol Yardım</h5>
							<p>+90 551 031 54 21 Numaradan Destek İstemek İçin Tıklayınız</p>
							<a  href={"tel:05510315421"} className="process-btn">Numarayı Ara <i className="ti-arrow-right"></i></a>
						</div>
					</div>
					<div className="slider-item">
						<div className="process-box">
							<h5 className="title"><i className="ti-panel"></i> Mobil Uygulama</h5>
							<p>Mobil Uygulamamız Üzerinden Konumunuzu Paylaşabilirsiniz.</p>
							<Link className="process-btn">Mobil Uygulamalarımız 
							<div>
							<i className="ti-android"></i>
                            <a target="_blank" href={"https://apps.apple.com/tr/app/lastikbulans/id1551542691?l=tr"} ><i className="ti-apple"></i></a>
							</div>
							</Link>
						</div>
					</div>
					<div className="slider-item">
						

						{this.state.isLoading == true ?
<div className="process-box">
		  <ReactLoading
          type={"spinningBubbles"}
          color={"white"}
          height={100}
          width={100}
        /> 
        <a style = {{fontSize:15}}>Konumunuz Gönderiliyor Lütfen Bekleyiniz ...</a>
        </div>:
<div className="process-box">

							<h5 className="title"><i className="ti-world"></i>Konum Paylaşımı</h5>
							<p>Üzerine Tıklayıp Destek İste Butonuna Basmanız Yeterli.</p>
							<Link onClick={() => {this._setLocation('')}} className="process-btn">Destek İste <i className="ti-arrow-right"></i></Link></div>
							}
						
					</div>
				</Slider>
			
			</>
		);
	}
}

export default ServiceSlider1;
