import React, { Component } from 'react';
import Slider from "react-slick"; 


class Testimonial1 extends Component{
	
	render(){
		
		const settings = {
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
		};
		
		return(
			<>
				<Slider {...settings} className="dots-style-1 arrow-none">
					<div className="slider-item">
						<div className="testimonial-bx style1">
							<div className="testimonial-content">
								<p>Konya'da yolda kaldım ve mobil uygulama üzerinden arkadaşlar konumuna ulaşarak destek verdiler.</p>
							</div>
							<div className="client-info">
								<div className="testimonial-info">
									<p>LastikBulans</p>
									<a target="_blank" href={"https://play.google.com/store/apps/details?id=com.lastikbulansyol"} style = {{color:'gray'}}><i className="fa fa-android  fa-3x "></i></a>
										<a target="_blank" href={"https://apps.apple.com/tr/app/lastikbulans/id1551542691?l=tr"} style = {{color:'gray', paddingLeft:15}}><i className="fa fa-apple fa-3x"></i></a>
								</div>
							</div>
						</div>
					</div>
					<div className="slider-item">
						<div className="testimonial-bx style1">
							<div className="testimonial-content">
								<p>Konya'de uzun yol yaparken aracımın lastiği patladı ve Lastikbulans'a ulaşarak hemen yardım desteği aldım</p>
							</div>
							<div className="client-info">
								<div className="testimonial-info">
									<p>LastikBulans</p>
									<a target="_blank" href={"https://play.google.com/store/apps/details?id=com.lastikbulansyol"} style = {{color:'gray'}}><i className="fa fa-android  fa-3x "></i></a>
										<a target="_blank" href={"https://apps.apple.com/tr/app/lastikbulans/id1551542691?l=tr"} style = {{color:'gray', paddingLeft:15}}><i className="fa fa-apple fa-3x"></i></a>
								</div>
							</div>
						</div>
					</div>
					<div className="slider-item">
						<div className="testimonial-bx style1">
							<div className="testimonial-content">
								<p>Web sitesi üzerinden konumumu paylaştım ve ustalar hızlı bir şekilde konumuma ulaştılar.</p>
							</div>
							<div className="client-info">
								<div className="testimonial-info">
									<p>LastikBulans</p>
									<a target="_blank" href={"https://play.google.com/store/apps/details?id=com.lastikbulansyol"} style = {{color:'gray'}}><i className="fa fa-android  fa-3x "></i></a>
										<a target="_blank" href={"https://apps.apple.com/tr/app/lastikbulans/id1551542691?l=tr"} style = {{color:'gray', paddingLeft:15}}><i className="fa fa-apple fa-3x"></i></a>
								</div>
							</div>
						</div>
					</div>
					<div className="slider-item">
						<div className="testimonial-bx style1">
							<div className="testimonial-content">
								<p>Mobil Uygulama üzerinden ustanın konumunu takip ederek güven verdi.</p>
							</div>
							<div className="client-info">
								<div className="testimonial-info">
									<p>Yol Yardım</p>
									<a target="_blank" href={"https://play.google.com/store/apps/details?id=com.lastikbulansyol"} style = {{color:'gray'}}><i className="fa fa-android  fa-3x "></i></a>
										<a target="_blank" href={"https://apps.apple.com/tr/app/lastikbulans/id1551542691?l=tr"} style = {{color:'gray', paddingLeft:15}}><i className="fa fa-apple fa-3x"></i></a>
								</div>
							</div>
						</div>
					</div>
				</Slider>
			</>
		);
	}
}

export default Testimonial1;
