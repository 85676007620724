import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';

// Images
import Logo from "../../../images/logo.png"

import axios from "axios";

const contactUrl = "https://konya.stechomeyazilim.info/apiwebyol/RestController.php?view=getContact";
const servicesUrl = "https://konya.stechomeyazilim.info/apiwebyol/RestController.php?view=getService";


class Header1 extends Component{
	constructor(){
		super()
		this.state={
			contactArray:[],
			servicesArray:[],
		}
	}

	getContact= async () => {
        try {      

     axios.get(contactUrl)
      .then(res => {
            res.data.cikti.map((item) => {
                this.setState({
					ID:item.ID,
					Adress_Text :  item.Adress_Text,
					Email_Adress:item.Email_Adress,
					Phone_Numer:item.Phone_Numer,
					Slogan_Home:item.Slogan_Home,
					Header_Text:item.Header_Text,
					Header_Icon: item.Header_Icon,
					Main_Logo: item.Main_Logo,
					Twitter_Adress: item.Twitter_Adress,
					Instagram_Adress: item.Instagram_Adress,
					Facebook_Adress: item.Facebook_Adress,
                      }, () => {
                      })
              })
      })
        } catch (error) {
          console.log("error" + error)
        }
      }
	  getSidebar = async () => {
		try {
		  axios.get(servicesUrl).then((res) => {
			this.setState(
			  {
				servicesArray: res.data.cikti,
			  },
			  () => {}
			);
		  });
		} catch (error) {
		  console.log("error" + error);
		}
	  };
	componentDidMount() {
		
		this.getContact();
		this.getSidebar();
		// Cart Dropdown 
		
        
		
		// Search Form Popup
        var searchForm = document.querySelector(".nav-search-bar")
        var closeBtn = document.getElementById("search-remove")
		
        

        closeBtn.addEventListener('click',function(){
            searchForm.classList.remove("show")
        })

        // Mobile Menu sidebar function
        var btn = document.querySelector('.menuicon');
        var nav = document.querySelector('.menu-links');
       
        function toggleFunc() {
            btn.classList.toggle("open");
            nav.classList.toggle("show");
        }

        btn.addEventListener('click', toggleFunc);

        // Mobile Submenu open close function
        var navMenu = [].slice.call(document.querySelectorAll('.menu-links > ul > li'));
        for (var y = 0; y < navMenu.length; y++) {
            navMenu[y].addEventListener('click', function () { menuClick(this) });
        }

        function menuClick(current) {
            const active = current.classList.contains("open")
            navMenu.forEach(el => el.classList.remove('open'));
            
            if(active){
                current.classList.remove('open') 
                console.log("active")
            } else{
                current.classList.add('open');
                console.log("close")
            }
        }
		
    }
	
	render(){
		return(
			<>
				
				{/* <!-- Header Top ==== --> */}
				<header className="header rs-nav">
					<div className="top-bar">
						<div className="container">
							<div className="row d-flex justify-content-between">
								<div className="topbar-left">
									<ul>
										<li><a href={"tel:"+this.state.Phone_Numer}><i className="la la-phone"></i>Bizi Arayın - {this.state.Phone_Numer}</a></li>
										<li><Link to="#"><i className="la la-clock"></i>7 / 24 Yol Yardım Desteği</Link></li>
										<li><a href={"mailto:"+this.state.Email_Adress}><i className="las la-envelope-open"></i>{this.state.Email_Adress}</a></li>
									</ul>
								</div>
								<div className="topbar-right">
									{/* <ul>
										<li><Link to="company-history">History</Link></li>
										<li><Link to="blog-standard">News</Link></li>
										<li><Link to="contact-1">Contact Us</Link></li>
									</ul> */}
								</div>
							</div>
						</div>
					</div>
					<Sticky enabled={true} className="sticky-header navbar-expand-lg">
						<div className="menu-bar clearfix">
							<div className="container clearfix">
								{/* <!-- Header Logo ==== --> */}
								<div className="menu-logo">
									<Link to="/"><img src={Logo} alt=""/></Link>
								</div>
								{/* <!-- Mobile Nav Button ==== --> */}
								<button className="navbar-toggler collapsed menuicon justify-content-end" type="button" data-toggle="collapse" data-target="#menuDropdown" aria-controls="menuDropdown" aria-expanded="false" aria-label="Toggle navigation">
									<span></span>
									<span></span>
									<span></span>
								</button>
								{/* <!-- Author Nav ==== --> */}
								<div className="secondary-menu">
									<div className="secondary-inner">
										<ul>
											
											
											<li className="d-none d-sm-block">
												<Link to="get-in-touch" className="btn">Bize Ulaşın</Link>
											</li>
										</ul>
									</div>
								</div>
								{/* <!-- Navigation Menu ==== --> */}
								<div className="menu-links navbar-collapse collapse justify-content-end" id="menuDropdown">
									<div className="menu-logo">
										<Link to="/"><img src={Logo} alt=""/></Link>
									</div>
									<ul className="nav navbar-nav">	
										<li className="active"><Link to="/">Ana Sayfa<i className="fa fa"></i></Link>
											{/* <ul className="sub-menu">
												<li><Link to="/"><span>Home - Default</span> </Link></li>
												<li><Link to="index-2"><span>Home - Energy & Power</span></Link></li>
												<li><Link to="index-3"><span>Home - Construction</span></Link></li>
												<li><Link to="index-4"><span>Home - Agricultural</span></Link></li>
											</ul> */}
										</li>
										<li className="has-mega-menu "><Link to="hakkimizda">Hakkımızda<i className="fa fa"></i></Link>
											
										</li>
										<li><Link to="#">Servislerimiz<i className="fa fa-chevron-down"></i></Link>
											<ul className="sub-menu">
												<li className="add-menu-left">
													<ul>
													{this.state.servicesArray.map(item =>(
														<li><Link to={{pathname: `/servislerimiz/${item.Service_Title.replace(/\s+/g, '-')}`, state: { ID: item.ID }}}>{item.Service_Title} </Link></li>
														))}
													</ul>
												</li>
											</ul>
										</li>
										<li><Link to="/sss">SSS<i className="fa"></i></Link>
										</li>
										<li className="has-mega-menu "><Link to="/hizmetlerimiz">Hizmetlerimiz <i className="fa fa"></i></Link>
											
										</li>
										
										<li><Link to="/blog">Blog <i className="fa fa"></i></Link>
											
										</li>
									</ul>
									<div className="nav-social-link">
										<Link to="#"><i className="fa fa-facebook"></i></Link>
										<Link to="#"><i className="fa fa-google-plus"></i></Link>
										<Link to="#"><i className="fa fa-linkedin"></i></Link>
									</div>
								</div>
								{/* <!-- Navigation Menu END ==== --> */}
							</div>
						</div>
					</Sticky>
					{/* <!-- Search Box ==== --> */}
					<div className="nav-search-bar">
						<form action="#">
							<input name="search" type="text" className="form-control" placeholder="Type to search"/>
							<span><i className="ti-search"></i></span>
						</form>
						<span id="search-remove"><i className="ti-close"></i></span>
					</div>
				</header>
				{/* <!-- Header Top END ==== --> */}
			</>
		);
	}
}

export default Header1;
