import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';

// Elements
import SolutionSidebar from '../elements/services/solution-sidebar';

// Images
import Banner1 from '../../images/banner/banner1.jpg';
import axios from "axios";
import base64 from 'react-native-base64';
import ReactHtml from 'raw-html-react';

class ServicesDetails extends Component {
	constructor(){
		super()
		this.state={
			contactArray:[]
		}
	}

		componentWillUpdate(){
		this.getBlogDetails();
	}

	componentDidMount(){
		this.getBlogDetails();
	}
	getBlogDetails= async () => {
        try {      

     axios.get(`https://konya.stechomeyazilim.info/apiwebyol/RestController.php?view=getServiceDetail&ServiceDetail_ID=${this.props.location.state.ID}`)
      .then(res => {
            res.data.cikti.map((item) => {
                this.setState({
					ID:item.ID,
					Service_Image :  item.Service_Image,
					Service_Title :  item.Service_Title,
					Service_Short_Desc :  item.Service_Short_Desc,
					Service_Long_Html :  base64.decode(item.Service_Long_Html),
                      }, () => {
                      })
              })
      })
        } catch (error) {
          console.log("error" + error)
        }
      }
	render(){
		return (
			<>
				<Header1 />
				
				{/* <!-- Content --> */}
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark parallax" style={{backgroundImage: "url("+Banner1+")"}}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">{this.state.Service_Title}</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i> Ana Sayfa</Link></li>
										<li>{this.state.Service_Title}</li>
									</ul>
								</div>
							 </div>
						</div>
					</div>
					
					<div className="content-block" id="content-area">
						
						<div className="section-area section-sp2 bg-fix bg-white">
							<div className="container about-video">
								<div className="row">
									<div className="col-lg-4 col-md-5 m-b30 wow fadeInUp" data-wow-delay="0.2s">
										<SolutionSidebar />	
									</div>
									<div className="col-lg-8 col-md-7 service-textarea portfolio-content">
										<div className="heading-bx text-left m-b20">
											<h3 className="title-head m-b0">{this.state.Service_Title}</h3>
											<div className="ttr-separator sepimg"></div>
										</div>
										<ReactHtml html={this.state.Service_Long_Html} />
									</div>
								</div>
							</div>
						</div>
						
				
						
					</div>
					
				</div>
				
				<Footer1 />
				
			</>
		);
	}
}

export default ServicesDetails;