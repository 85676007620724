import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Accordion, Card } from 'react-bootstrap';

// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';
import base64 from 'react-native-base64';
import ReactHtml from 'raw-html-react';
import axios from "axios";

// Elements
import ClientLogo from '../elements/client-logo/client-logo-1';

// Images
import Banner1 from '../../images/banner/banner1.jpg';

const urlFaq = "https://konya.stechomeyazilim.info/apiwebyol/RestController.php?view=getFaqs"

class Faq1 extends Component {
	constructor(){
		super()
		this.state={
			faqsArray:[]
		}
	}
	componentDidMount(){
		this.getFaqs();
	}

	getFaqs = async () => {
		try {
		  axios.get(urlFaq).then((res) => {
			this.setState(
			  {
				faqsArray: res.data.cikti,
			  },
			  () => {}
			);
		  });
		} catch (error) {
		  console.log("error" + error);
		}
	  };
	render(){
		return (
			<>
				<Header1 />
				
				{/* <!-- Content --> */}
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark parallax" style={{backgroundImage: "url("+Banner1+")"}}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">Sıkça Sorulan Sorular</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i> Ana Sayfa</Link></li>
										<li>SSS</li>
									</ul>
								</div>
							 </div>
						</div>
					</div>
					
					<div className="content-block" id="content-area">
						
						<div className="section-area section-sp1">
							<div className="container">
								<div className="heading-bx text-center">
									<h6 className="title-ext m-b0">Sorularınıza Yanıt Bulun</h6>
									<h2 className="title-head m-b0">Sıkça Sorulan Sorular Nelerdir?</h2>
									<div className="ttr-separator sepimg"></div>
								</div>
								<div className="row">
									<div className="col-md-12">
										<Accordion className="ttr-accordion primary" defaultActiveKey="0">
											{this.state.faqsArray.map(item=>(
												<Card>
												<Accordion.Toggle as={Card.Header} eventKey={item.ID}>
													<Link to="#">{item.Faqs_Title}</Link>
												</Accordion.Toggle>
												<Accordion.Collapse eventKey={item.ID}>
													<Card.Body>
														<ReactHtml html={base64.decode(item.Faqs_Desc)}/>
													</Card.Body>
												</Accordion.Collapse>
											</Card>
											))}

										</Accordion>
									</div>	
									
								</div>
							</div>
						</div>
						
						<div className="section-area section-sp2 bg-gray">
							<div className="container wow fadeIn" data-wow-delay="0.8s">
								<div className="heading-bx text-center">
									<h6 className="title-ext m-b0">Our Partner</h6>
									<h2 className="title-head m-b0">Partners & Clients</h2>
									<div className="ttr-separator sepimg"></div>
								</div>
								<ClientLogo />
							</div>
						</div>
						
					</div>
					
				</div>
				
				<Footer1 />
				
			</>
		);
	}
}

export default Faq1;