import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header4 from '../layout/header/header4';
import Footer2 from '../layout/footer/footer2';
import Masonry from "../elements/portfolio/masonry2";

import ReactHtml from 'raw-html-react';

// Elements
import Slider from '../elements/slider/slider4';
import Testimonial from '../elements/testimonial/testimonial1';
import Counter from '../elements/counter/counter';
import element from '../../vendors/themify/animation.css';
import axios from "axios";
import base64 from 'react-native-base64';
import ServiceSlider1 from '../elements/services/service-slider-1';

import ServicesPic1 from "../../images/our-services/pic1.jpg"
import ServicesPic2 from "../../images/our-services/pic2.jpg"
import ServicesPic3 from "../../images/our-services/services-1/pic3.jpg"
import ServicesPic4 from "../../images/our-services/services-1/pic4.jpg"
import ServicesPic5 from "../../images/our-services/services-1/pic5.jpg"
import ServicesPic6 from "../../images/our-services/services-1/pic6.jpg"
import BackBg2 from "../../images/background/bg2.png"
import ReactLoading from "react-loading";

const blogsUrl = "https://konya.stechomeyazilim.info/apiwebyol/RestController.php?view=getBlog";

var options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

  const contactUrl = "https://konya.stechomeyazilim.info/apiwebyol/RestController.php?view=getContact";
  const imgUrl = "https://lastikbulansadmin.stechomeyazilim.info";



 let crdVariable = null;
 let crdVariableCheck = false;

async function success(pos) {

crdVariable = pos.coords;
crdVariableCheck = true;



  const APIKEY = 'AIzaSyBocJDGN0UtiQXa-R4Aa7salQeABS9XdBA';

      const url = `https://maps.googleapis.com/maps/api/geocode/json?key=${APIKEY}&latlng=${crdVariable.latitude},${crdVariable.longitude}&sensor=true`;


      let rsp=await fetch(url)
      rsp=await rsp.json()



 axios({
            method: "POST",
            url: "https://lastikbulans.herokuapp.com/pushNotification/",
            data: ({
        USERID: 3,
        title:"Lastikbulans Konya",
        body:rsp.results[0].formatted_address,
    }),
            headers: {
              "Content-Type": "application/json"
            }
          }).then(res => {
           
          });

}

function errors(err) {
  console.warn(`ERROR(${err.code}): ${err.message}`);
}


var styleBottom = {
  display:'flex',
 justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: "#F8F8F8",
    borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    padding: "20px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "70px",
    zIndex: 99,
    width: "100%",
    fileUpload:false,
    isLoading:false
}

var phantom = {
zIndex:99,
  padding: '10px',
  height: '30px',
  width: '30px',
}


class Index4 extends Component {
	constructor(){
		super()
		this.state={
			blogsArray:[],
			crd:[],
			file: null,
chkbox:false,
arrayServices:[],
fotoSend:false
		}
		
   this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
	}


	_buttonValue = () => {
        this.setState({
            fotoSend: !this.state.fotoSend,
          })
    
    }

_setLocation = async () => {


  this.setState({isLoading:true});

  if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.success, errors, options);
}else {
    
}


}


	success = async (pos) => {

crdVariable = pos.coords;


  const APIKEY = 'AIzaSyBocJDGN0UtiQXa-R4Aa7salQeABS9XdBA';

      const url = `https://maps.googleapis.com/maps/api/geocode/json?key=${APIKEY}&latlng=${crdVariable.latitude},${crdVariable.longitude}&sensor=true`;


      let rsp=await fetch(url)
      rsp=await rsp.json()



 axios({
            method: "POST",
            url: "https://lastikbulans.herokuapp.com/pushNotification/",
            data: ({
        USERID: 3,
        title:"Lastikbulans Türkiye",
        body:rsp.results[0].formatted_address,
    }),
            headers: {
              "Content-Type": "application/json"
            }
          }).then(res => {
             this.setState({isLoading:false});

          });



    axios({
            method: "POST",
            url: "https://lastikbulans.herokuapp.com/work/insert",
            data: ({
        Location_Text: rsp.results[0].formatted_address,
        Location_City:  rsp.results[4].formatted_address,
        Location_Long:crdVariable.longitude,
        Location_Lat:crdVariable.latitude,
        Customer_ID:1166,
        Worker_ID:73,
        Worker_State:0,
        Work_Note:"website",
        Work_Status_ID:1,
        Service_2_ID:1,
        Car_Type_ID:4,
        Work_Send_Worker_ID:3,
        Work_WebSite_Check:true,
    }),
            headers: {
              "Content-Type": "application/json"
            }
          }).then(res => {

  this.setState({isLoading:false});

         alert("Bize Konumunuz Başarıyla Gönderildi, Sizinle İletişime Geçeceğiz");

          });

         alert("Bize Konumunuz Başarıyla Gönderildi, Sizinle İletişime Geçeceğiz");

                       this.setState({isLoading:false});


}



  _getIndexServices = async (value) => {
    try {
      axios
        .get(
          `https://konya.stechomeyazilim.info/apiwebyol/RestController.php?view=getProject&ProjectCategory_ID=${value}`
        )
        .then((res) => {
          this.setState(
            {
              arrayServices: res.data.cikti,
            },
            () => {
            console.log("fdsfsdf", res.data.cikti)
            }
          );
        });
    } catch (error) {
      console.log("error" + error);
    }
  };
		getContact= async () => {
        try {      

     axios.get(contactUrl)
      .then(res => {
            res.data.cikti.map((item) => {
                this.setState({
					ID:item.ID,
					Adress_Text :  item.Adress_Text,
					Email_Adress:item.Email_Adress,
					Phone_Numer:item.Phone_Numer,
					Slogan_Home:item.Slogan_Home,
					Header_Text:item.Header_Text,
					Header_Icon: item.Header_Icon,
					Main_Logo: item.Main_Logo,
					Twitter_Adress: item.Twitter_Adress,
					Instagram_Adress: item.Instagram_Adress,
					Facebook_Adress: item.Facebook_Adress,
                      }, () => {
                      })
              })
      })
        } catch (error) {
          console.log("error" + error)
        }
      }

	componentDidMount(){
	
		this.getBlogs();
		this.getContact();
		this._getIndexServices("");
	}

	getBlogs = async () => {
		try {
		  axios.get(blogsUrl).then((res) => {
			this.setState(
			  {
				blogsArray: res.data.cikti,
			  },
			  () => {}
			);
		  });
		} catch (error) {
		  console.log("error" + error);
		}
	  };


	handleChangeChk = () => {
        this.setState({
            chkbox: !this.state.chkbox,
          })
    
    }

  onFormSubmit(e){
  if (this.state.file == null){
            alert("Lütfen Yükleyeceğiniz Fotoğrafı Seçiniz");

  }
    if (this.state.file != null){
            e.preventDefault();
        const formData = new FormData();
        formData.append('file',this.state.file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        axios.post("https://lastikbulans.herokuapp.com/upload",formData,config)
            .then(async(response) => {
            this.setLocation(response.data);

            this.setState({file:null});

            alert("Fotoğraf Yükleme İşlemi Başarıyla Tamamlandı");
            }).catch((error) => {
        }); 
  }
      
    }
    onChange(e) {
        this.setState({file:e.target.files[0]});
    }

	render(){
		
		return (
			<>
				<Header4/>
			
				<div className="page-content bg-white">
					
					<Slider/>
					
					<div className="content-block" id="content-area">
						
						
							<div className="section-area process-area">
							{this.state.isLoading == true ?
							<div className="container">

        <a className="project-btn" style = {{fontSize:15}}>Konumunuz Gönderiliyor Lütfen Bekleyiniz ...</a>
        <ServiceSlider1 /></div>:

							<div className="container">
								<Link  onClick={() => {this._setLocation('')}} className="project-btn"><i className="icon ti-location-pin"></i> Konum Gönder</Link>
								<ServiceSlider1 />
							</div>
							}
						</div>

					<div className="section-area section-sp1 bg-gray">

							<div className="container">
								<div className="heading-bx text-center">
									<h6 className="title-ext m-b0">LastikBulans</h6>
								   <h6 className="title-ext m-b0">Lastik Ambulansı</h6>

									<h2 className="title-head m-b0">Bizimle Konumunuzu Paylaşarak Hızlı Servis Hizmeti Almak İster misiniz? </h2>
									<div className="ttr-separator sepimg"></div>
								</div>
								{/* <Projects /> */}
					<div className="heading-bx text-center">
								
			{this.state.fotoSend == true ? 
			<form onSubmit={this.onFormSubmit}>
                <input  className="form-control"  type="file" name="file" onChange= {this.onChange} />
                <button className="btn btn-dark" type="submit">Fotoğraf Gönder</button>

            </form> : <button onClick={() => {this._buttonValue()}} className="btn btn-dark" type="submit">Bize Aracınızın Fotoğrafını Göndermek İster Misiniz?</button>	}						
            {this.state.isLoading == true ?
             <div style = {{paddingTop:10}}>
            
        <a style = {{fontSize:15}}>Konumunuz Gönderiliyor Lütfen Bekleyiniz ...</a> </div> :

                <div style = {{paddingTop:10}}>
<button onClick={() => {this._setLocation('')}} className="btn btn-dark" type="submit">Konum Gönder</button>
</div>
}
            									</div>

							</div>
						</div>
						

		
                  						<div style = {{backgroundColor:'white', paddingTop:40}}>
</div>

						

							<div className="section-area section-sp2 bg-white" style={{backgroundImage:"url("+BackBg2+")", backgroundPosition:"bottom", backgroundSize:"100%"}}>
							<div className="container">
								<div className="row">
									<div className="col-lg-12">
										<div className="heading-bx text-center">
											<h6 className="title-ext m-b0">LastikBulans</h6>
											<h2 className="title-head m-b0">Hizmetlerimiz</h2>
											<div className="ttr-separator sepimg"></div>
										</div>
									</div>
								</div>
<div className="row">

{this.state.arrayServices.map((item, index) => (
									<div className="col-xl-4 col-lg-6 col-md-6 m-b30">
										<div className="service-bx style1">
											<div className="service-media">
												<img src={imgUrl + item.Project_Main_Image} alt=""/>
											</div>
											<div className="service-info">
												<h4 className="title">{item.Project_Title}</h4>
												<a   href = "tel:+905510315421" className="btn radius-sm"><h8>Ara</h8></a>

												<div style = {{marginTop:10}}>
											
												<Link onClick={() => this._setLocation("")} className="btn radius-sm"><h8>Konum Gönder</h8></Link>
												</div>
											</div>
										</div>
									</div>

 ))}
							
																</div>

								
							</div>
						</div>
						
						<div className="section-area bg-primary section-sp2 client-appoint-box">
							<div className="container">
								<div className="row">
									<div className="col-lg-6 col-md-12">
										<div className="heading-bx text-white">
											<h6 className="title-ext m-b0">Değerlendirmeler</h6>
											<h2 className="title-head m-b0">Müşteriler Hakkımızda Neler Düşünüyor?</h2>
											<div className="ttr-separator sepimg2"></div>
										</div>
										<div className="swiper-container client-area-box">		
											<Testimonial />
										</div>
									</div>
									<div className="col-lg-6 col-md-12">
										<form className="ajax-form" action="script/contact.php">
											<div className="apoint-btn-box">Hemen Ulaşın</div>
											<div className="ajax-message"></div>
											<div className="heading-bx text-white">
												<Link className="btn radius-sm"><h6 className="title-ext m-b0">Bize Ulaşın</h6></Link>
												<h2 className="title-head m-b0">Hemen Ulaşın</h2>
												<div className="ttr-separator sepimg2"></div>
											</div>
											<div className="row placeani appoint-bx1">
												<div className="col-lg-4 col-md-4">
													<div className="form-group">
														<div className="input-group input-bx">
															<input name="name" type="text" required className="form-control valid-character" placeholder="İsminiz"/>
														</div>
													</div>
												</div>
												
												<div className="col-lg-4 col-md-4">
													<div className="form-group">
														<div className="input-group input-bx">
															<input name="phone" type="text" required className="form-control int-value" placeholder="Telefon Numaranız"/>
														</div>
													</div>
												</div>
												<div className="col-lg-12 col-md-12">
													<div className="form-group">
														<div className="input-group input-bx">
															<textarea name="message" rows="4" className="form-control" placeholder="Sorununuz" required ></textarea>
														</div>
													</div>
												</div>
												<div className="col-lg-12 col-md-12">
													<button name="submit" type="submit" value="Submit" className="btn button-md">Gönder</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
						
						<div className="section-area bg-white section-sp1 blog-area">
							<div className="container">
								<div className="row heading-bx align-items-end">
									<div className="col-lg-8 col-md-9">
										<h6 className="title-ext m-b0">Lastikbulans</h6>
										<h6 className="title-ext m-b0">Lastik Ambulansı</h6>

										<h2 className="title-head m-b0">Son Blog Yazılarımız</h2>
										<div className="ttr-separator sepimg"></div>
									</div>
									<div className="col-lg-4 col-md-3">
										<Link to="blog" className="btn float-right">Bütün Blogları Göster</Link>
									</div>
								</div>
								<div className="row">
								{this.state.blogsArray.slice(0,1).map(item=>(
									<div className="col-md-6">
									<div className="recent-news">
										<div className="action-box">
											<img src={imgUrl + item.Blog_Image_Url} alt=""/>
										</div>
										<div className="info-bx">
											<ul className="media-post">
												<li className="date"><Link to={{pathname: `/blog-detay/${item.Blog_Title.replace(/\s+/g, '-').toLowerCase()}`, state: { ID: item.ID }}}>{item.Blog_Date.slice(0,10)}</Link></li>
											</ul>
											<h3 className="post-title"><Link to={{pathname: `/blog-detay/${item.Blog_Title.replace(/\s+/g, '-').toLowerCase()}`, state: { ID: item.ID }}}>{item.Blog_Title} </Link></h3>
											<p>{item.Blog_Short_Desc}</p>

										</div>
									</div>
								</div>
								))}
									

									<div className="col-md-6">
										<div className="side-post">
											{this.state.blogsArray.slice(1,4).map(item=>(
												<div className="blog-post blog-md clearfix wow fadeInUp" data-wow-delay="0.2s">
												<div className="ttr-post-media"> 
													<Link to={{pathname: `/blog-detay/${item.Blog_Title.replace(/\s+/g, '-').toLowerCase()}`, state: { ID: item.ID }}}><img src={imgUrl + item.Blog_Image_Url} alt=""/></Link> 
												</div>
												<div className="ttr-post-info">
													<ul className="media-post">
														<li className="date"><Link to="#"><i className="fa fa-calendar-o"></i>{item.Blog_Date.slice(0,10)}</Link></li>
													</ul>
													<h4 className="post-title"><Link to={{pathname: `/blog-detay/${item.Blog_Title.replace(/\s+/g, '-')}`, state: { ID: item.ID }}}>{item.Blog_Title} </Link></h4>

												<p>{item.Blog_Short_Desc}</p>

												</div>
												
											</div>
											))}
											
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>
					
					<Counter />
 <div style={phantom} />



            <div style={styleBottom}>
		   <div style = {{display:'flex',position:'absolute',right:65,bottom:10}}>
       

<div class="element">
								<a target="_blank" style = {{color:'white'}}><i className="fa fa-phone  fa-2x "></i></a>

						</div>		

								<a  style={{ color:'red', paddingTop:0,paddingLeft: 20	}} 
								href={"tel:"+this.state.Phone_Numer}>

								<h5 className="title-head m-b0">Tel: {this.state.Phone_Numer}</h5></a>
								
														</div>		


					  <div style = {{ position:'absolute',left:5,top:0}}>

						<a target="_blank" href={"https://play.google.com/store/apps/details?id=com.lastikbulansyol"} style = {{color:'red'}}><i className="fa fa-android  fa-2x "></i></a>
										<a target="_blank" href={"https://apps.apple.com/tr/app/lastikbulans/id1551542691?l=tr"} style = {{color:'blue', paddingLeft:15}}><i className="fa fa-apple fa-2x"></i></a>

                    
             

</div>
							
            </div>

					<Footer2 />
					
				</div>
				
			</>
		);
	}
}

export default Index4;
