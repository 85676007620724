import React, { Component } from 'react';

// Elements
import Count from '../../elements/counter/counter-sensor';
import axios from "axios";


const counterUrl = "https://konya.stechomeyazilim.info/apiwebyol/RestController.php?view=getCounter";


class Counter extends Component{
	constructor(){
		super()
		this.state={
			counterArray:[]
		}
	}

	componentDidMount(){
		this.getCounter();
	}

	getCounter = async () => {
		try {
		  axios.get(counterUrl).then((res) => {
			this.setState(
			  {
				counterArray: res.data.cikti,
			  },
			  () => {}
			);
		  });
		} catch (error) {
		  console.log("error" + error);
		}
	  };
	render(){
		return(
			<>
				<div className="section-area bg-white section-sp1">
					<div className="container">
						<div className="heading-bx text-center">
							<h6 className="title-ext m-b0">Lastikbulans</h6>
							<h2 className="title-head m-b0">Hizmetlerimizle<br/> Lastikbulans</h2>
							<div className="ttr-separator sepimg"></div>
						</div>
						<div className="row">
							{this.state.counterArray.map(item => (
							<div className="col-lg-3 col-md-6 col-sm-6 col-6 m-b30 wow fadeIn" data-wow-delay="0.2s">
								<div className="counter-style-2">
									<div className="counter-bx">
										<Count counter={item.Counter_Count}/>
									</div>
									<span className="counter-text">{item.Counter_Title}</span>
									<i className="la {autotowing-man}"></i>
								</div>
							</div>	
							))}
							
							
							
							
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Counter;
