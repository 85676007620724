import React, { Component } from 'react';
import base64 from 'react-native-base64';
import ReactHtml from 'raw-html-react';
import axios from "axios";

const blogsUrl = "https://konya.stechomeyazilim.info/apiwebyol/RestController.php?view=getAbout"

class About3 extends Component{
	constructor(){
		super()
		this.state={
			blogsArray:[]
		}
	}
	componentDidMount(){
		this.getBlogs();
	}

	getBlogs = async () => {
		try {
		  axios.get(blogsUrl).then((res) => {
			this.setState(
			  {
				blogsArray: res.data.cikti,
			  },
			  () => {}
			);
		  });
		} catch (error) {
		  console.log("error" + error);
		}
	  };
	render(){
		return(
			<>
				<div className="row align-items-center">
					<div className="col-lg-6 col-md-12 wow fadeIn m-md-b40" data-wow-delay="0.3s" style={{visibility: "visible", animationDelay: "0.3s", animationName: "fadeIn"}}>
						<div className="heading-bx m-b20">
							<h6 className="title-ext m-b0">Hoş Geldiniz!</h6>
							<h2 className="title-head m-b0">Lastikbulans Hakkında</h2>
							<div className="ttr-separator sepimg"></div>
							{this.state.blogsArray.map(item=>(
							<p className="head-px2"><ReactHtml html={base64.decode(item.About_Us_Desc)}/></p>

							))}
						</div>
					</div>
						
				</div>
			</>
		);
	}
}

export default About3;
