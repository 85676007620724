import React, { Component } from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom';

// Images

import axios from "axios";
import ReactLoading from "react-loading";


const sliderUrl = "https://konya.stechomeyazilim.info/apiwebyol/RestController.php?view=getSlider";
const url = "https://lastikbulansadmin.stechomeyazilim.info/";
const contactUrl = "https://konya.stechomeyazilim.info/apiwebyol/RestController.php?view=getContact";

var options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};


 let crdVariable = null;
 let crdVariableCheck = false;


function errors(err) {
  console.warn(`ERROR(${err.code}): ${err.message}`);
}


class Slider4 extends Component{
	constructor(props){
		super(props);
		this.state={
			sliderArray:[],
			Phone_Numer:0,
			locationCheck:false,
			isLoading:false,
		}
	}


	componentDidMount(){	
		this.getSlider();
	}



_setLocation = async () => {


  this.setState({isLoading:true});

  if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.success, errors, options);
}else {
    
}


}


	success = async (pos) => {

crdVariable = pos.coords;


  const APIKEY = 'AIzaSyBocJDGN0UtiQXa-R4Aa7salQeABS9XdBA';

      const url = `https://maps.googleapis.com/maps/api/geocode/json?key=${APIKEY}&latlng=${crdVariable.latitude},${crdVariable.longitude}&sensor=true`;


      let rsp=await fetch(url)
      rsp=await rsp.json()



 axios({
            method: "POST",
            url: "https://lastikbulans.herokuapp.com/pushNotification/",
            data: ({
        USERID: 3,
        title:"Lastikbulans Türkiye",
        body:rsp.results[0].formatted_address,
    }),
            headers: {
              "Content-Type": "application/json"
            }
          }).then(res => {
             this.setState({isLoading:false});

          });



    axios({
            method: "POST",
            url: "https://lastikbulans.herokuapp.com/work/insert",
            data: ({
        Location_Text: rsp.results[0].formatted_address,
        Location_City:  rsp.results[4].formatted_address,
        Location_Long:crdVariable.longitude,
        Location_Lat:crdVariable.latitude,
        Customer_ID:1166,
        Worker_ID:73,
        Worker_State:0,
        Work_Note:"website",
        Work_Status_ID:1,
        Service_2_ID:1,
        Car_Type_ID:4,
        Work_Send_Worker_ID:3,
        Work_WebSite_Check:true,
    }),
            headers: {
              "Content-Type": "application/json"
            }
          }).then(res => {

  this.setState({isLoading:false});

         alert("Bize Konumunuz Başarıyla Gönderildi, Sizinle İletişime Geçeceğiz");

          });

         alert("Bize Konumunuz Başarıyla Gönderildi, Sizinle İletişime Geçeceğiz");

                       this.setState({isLoading:false});


}




	getSlider = async () => {
		try {
		  axios.get(sliderUrl).then((res) => {
			this.setState(
			  {
				sliderArray: res.data.cikti,
			  },
			  () => {}
			);
		  });
		} catch (error) {
		  console.log("error" + error);
		}
	  };


	  	getContact= async () => {
        try {      

     axios.get(contactUrl)
      .then(res => {
            res.data.cikti.map((item) => {
                this.setState({
					ID:item.ID,
					Adress_Text :  item.Adress_Text,
					Email_Adress:item.Email_Adress,
					Phone_Numer:item.Phone_Numer,
					Slogan_Home:item.Slogan_Home,
					Header_Text:item.Header_Text,
					Header_Icon: item.Header_Icon,
					Main_Logo: item.Main_Logo,
					Twitter_Adress: item.Twitter_Adress,
					Instagram_Adress: item.Instagram_Adress,
					Facebook_Adress: item.Facebook_Adress,
                      }, () => {
                      })
              })
      })
        } catch (error) {
          console.log("error" + error)
        }
      }

	render(){

		const settings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
		};
		
		return(
			<>
				
				<Slider {...settings} className="tt-slider slider-sp0 slider-four">
					{this.state.sliderArray.map(item=> (
						<div className="slider-item">
						<div className="slider-thumb">
							<img src={url+item.SliderImage} alt=""/>
						</div>
						<div className="slider-content text-center text-white">
							<div className="container">
								<div className="content-inner">
									<img className="icon-cell" src={item.Slider_Icon} alt=""/>
									<h2 style = {{color:item.Title_Color}}className="title">{item.Slider_Title}</h2>
									<div>
									<Link className="btn radius-sm"><h6 className="title-ext m-b0">{item.Slider_Desc}</h6></Link> </div>
{this.state.isLoading == true ?
<div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>

		  <ReactLoading
          type={"spinningBubbles"}
          color={"white"}
          height={100}
          width={100}
        /> 
        <a style = {{fontSize:15}}>Konumunuz Gönderiliyor Lütfen Bekleyiniz ...</a></div>:
        <div style = {{paddingTop:20 }}>
									<Link onClick={() => this._setLocation("")} className="btn radius-sm"><h6 className="title-ext m-b0">Bize Konum Gönderiniz</h6></Link></div> }
								</div>
							</div>
						</div>
					</div>	
					))}

				</Slider>
				
			</>
		);
	}
}

export default Slider4;