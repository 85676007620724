import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import RecentPic1 from "../../../images/blog/recent-blog/pic1.jpg"

import axios from "axios";

const blogsUrl = "https://konya.stechomeyazilim.info/apiwebyol/RestController.php?view=getBlog";
const imgUrl = "https://lastikbulansadmin.stechomeyazilim.info";




class BlogSidebar extends Component{
	constructor(){
		super()
		this.state={
			blogsArray:[]
		}
	}
	componentDidMount(){
		this.getBlogs();
	}

	getBlogs = async () => {
		try {
		  axios.get(blogsUrl).then((res) => {
			this.setState(
			  {
				blogsArray: res.data.cikti,
			  },
			  () => {}
			);
		  });
		} catch (error) {
		  console.log("error" + error);
		}
	  };
	render(){
		return(
			<>
				<aside className="side-bar sticky-top aside-bx">
					<div className="widget">
						<div className="search-bx style-1">
							<form role="search" method="post">
								<div className="input-group">
									<input name="text" className="form-control" placeholder="Aranacak Kelimeleri Giriniz" type="text"/>
									<span className="input-group-btn">
										<button type="submit" className="fa fa-search text-primary"></button>
									</span> 
								</div>
							</form>
						</div>
					</div>
					<div className="widget recent-posts-entry">
						<h6 className="widget-title">Son Paylaşımlar</h6>
						<div className="widget-post-bx">
							{this.state.blogsArray.slice(0,3).map(item=>(
								<div className="widget-post clearfix">
								<div className="ttr-post-media"> <img src={imgUrl + item.Blog_Image_Url} width="200" height="143" alt=""/> </div>
								<div className="ttr-post-info">
									<div className="ttr-post-header">
										<h6 className="post-title"><Link to={{pathname: `/blog-detay/${item.Blog_Title.replace(/\s+/g, '-').toLowerCase()}`, state: { ID: item.ID }}}>{item.Blog_Title}</Link></h6>
									</div>
									<ul className="media-post">
										<li><Link to="#">{item.Blog_Date.slice(0,10)}</Link></li>
									</ul>
								</div>
							</div>
							))}
							
						</div>
					</div>
					{/* <div className="widget widget_gallery gallery-grid-3">
						<h6 className="widget-title">Our Gallery</h6>
						<GalleryImg />
					</div> */}
					{/* <div className="widget widget_tag_cloud">
						<h6 className="widget-title">Tags</h6>
						<div className="tagcloud"> 
							<Link to="#">Design</Link> 
							<Link to="#">User interface</Link> 
							<Link to="#">SEO</Link> 
							<Link to="#">WordPress</Link> 
							<Link to="#">Development</Link> 
							<Link to="#">Joomla</Link> 
							<Link to="#">Design</Link> 
							<Link to="#">User interface</Link> 
							<Link to="#">SEO</Link> 
							<Link to="#">WordPress</Link> 
							<Link to="#">Development</Link> 
							<Link to="#">Joomla</Link> 
							<Link to="#">Design</Link> 
							<Link to="#">User interface</Link> 
							<Link to="#">SEO</Link> 
							<Link to="#">WordPress</Link> 
							<Link to="#">Development</Link> 
							<Link to="#">Joomla</Link> 
						</div>
					</div> */}
				</aside>
			</>
		);
	}
}

export default BlogSidebar;
