import React, { Component } from 'react';
import { Link } from 'react-router-dom';


import AboutPic3 from '../../../images/about/pic3.jpg';
import HeadPhones from '../../../images/icon/headphones.png';
import axios from "axios";

const servicesUrl = "https://konya.stechomeyazilim.info/apiwebyol/RestController.php?view=getService";
const contactUrl = "https://konya.stechomeyazilim.info/apiwebyol/RestController.php?view=getContact";

class SolutionSidebar extends Component{
	constructor(){
		super()
		this.state={
			servicesArray:[]
		}
	}
	componentDidMount(){
		this.getSidebar();
		this.getContact();
	}


	getContact= async () => {
        try {      

     axios.get(contactUrl)
      .then(res => {
            res.data.cikti.map((item) => {
                this.setState({
					ID:item.ID,
					
					Phone_Numer:item.Phone_Numer,
					
                      }, () => {
                      })
              })
      })
        } catch (error) {
          console.log("error" + error)
        }
      }
	getSidebar = async () => {
		try {
		  axios.get(servicesUrl).then((res) => {
			this.setState(
			  {
				servicesArray: res.data.cikti,
			  },
			  () => {}
			);
		  });
		} catch (error) {
		  console.log("error" + error);
		}
	  };
	render(){
		return(
			<>
				<aside className="sticky-top">
					<div className="widget">
						<ul className="service-list">
							{this.state.servicesArray.map(item=>(
								<li>
								<Link to={{pathname: `/servislerimiz/${item.Service_Title.replace(/\s+/g, '-')}`, state: { ID: item.ID }}}>{item.Service_Title} <i className="fa fa-angle-right"></i> </Link>
							</li>
							))}
						</ul>
					</div>
				
					<div className="widget">
						<div className="help-bx">
							<div className="media">
								<img src={AboutPic3} alt=""/>
							</div>
							<div className="info text-white">
								<img src={HeadPhones} alt=""/>
								<h5 className="title">Yolda mı kaldınız?</h5>
								<p>Yol Yardım Desteği</p>
								<a href={"tel:"+this.state.Phone_Numer} className="btn-secondry">Bizi Ara</a>
							</div>
						</div>
					</div>
				</aside>
			</>
		);
	}
}

export default SolutionSidebar;
