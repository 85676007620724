import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'

// Elements
import BackToTop from './elements/back-top';

import Index from './pages/';

import Index4 from './pages/index-4';

import About3 from './pages/about-3';
import BlogClassicSidebar from './pages/blog-classic-sidebar';
import BlogDetailsSidebar from './pages/blog-details-sidebar';
import BlogDetails from './pages/blog-details';
import ComingSoon from './pages/coming-soon';
import Error404 from './pages/error-404';
import Faq2 from './pages/faq-2';
import GetInTouch from './pages/get-in-touch';
import Partners from './pages/partners';




import ServicesDetails from './pages/services-details';

import Project from './pages/projects-grid-3';




class Markup extends Component{
	render(){
		return(
			
			<>
			
				<BrowserRouter basename={'/'}>
				
					<Switch>
						
					
						<Route path='/' exact component={Index4} />
					
						<Route path='/hakkimizda' component={About3} />
						<Route path='/blog' component={BlogClassicSidebar} />
						<Route path='/blog-details-sidebar' component={BlogDetailsSidebar} />
						<Route path='/blog-detay' component={BlogDetails} />
						<Route path='/coming-soon' component={ComingSoon} />
						
						<Route path='/error-404' component={Error404} />
						<Route path='/sss' component={Faq2} />
						<Route path='/iletisim' component={GetInTouch} />
						<Route path='/partners' component={Partners} />

					   <Route path='/hizmetlerimiz' component={Project} />

	
			
						<Route path='/servislerimiz' component={ServicesDetails} />
						
					
						
						
					</Switch>
				</BrowserRouter>
				
				<BackToTop />
			
			</>
		);
	}
}

export default Markup;
